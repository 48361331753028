.contact {
  position: relative;
  min-height: 100vh;
  padding: 50px 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #e3f6f5;
  border-top: 10px solid #00c894;
}

.contact .content {
  max-width: 800px;
  text-align: center;
}
.contact .content p {
  font-weight: 500;
  color: #000;
}
.container-2 {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}

.container-2 .contactInfo {
  width: 50%;
  display: flex;
  flex-direction: column;
}

.container-2 .contactInfo .box {
  position: relative;
  padding: 20px 0;
  display: flex;
}

.container-2 .contactInfo .box .icon {
  min-width: 60px;
  height: 60px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-size: 22px;
  color: #00c894;
}

.container-2 .contactInfo .box .text {
  display: flex;
  margin-left: 20px;
  font-size: 16px;
  color: #000;
  flex-direction: column;
  font-weight: 300;
}
.container-2 .contactInfo .box .text h3 {
  font-weight: 500;
  color: #000;
}

.contactForm {
  width: 40%;
  padding: 40px;
  background: #fff;
}
.contactForm h2 {
  font-size: 30px;
  color: #333;
  font-weight: 500;
}
.contactForm .inputBox {
  position: relative;
  width: 100%;
  margin-top: 10px;
}

.contactForm .inputBox input,
.contactForm .inputBox textarea {
  width: 100%;
  padding: 5px 0;
  font-size: 16px;
  margin: 10px 0;
  border: none;
  border-bottom: 2px solid #333;
  outline: none;
  resize: none;
}
.contactForm .inputBox span {
  position: absolute;
  left: 0;
  padding: 5px 0;
  font-size: 16px;
  margin: 10px 0;
  pointer-events: none;
  transition: 0.5s;
  color: #666;
}

.contactForm .inputBox input:focus ~ span,
.contactForm .inputBox input:valid ~ span,
.contactForm .inputBox textarea:focus ~ span,
.contactForm .inputBox textarea:valid ~ span {
  color: #e91e63;
  font-size: 12px;
  transform: translateY(-20px);
}
.contactForm .inputBox input[type='submit'] {
  width: 100px;
  background: #00c894;
  color: #fff;
  border: none;
  cursor: pointer;
  padding: 10px;
  font-size: 18px;
}
.contactForm .inputBox input[type='submit']:hover {
  transform: scale(1.07);
  transition: all 0.5s ease-out;
}

@media screen and (max-width: 991px) {
  .contact {
    padding: 50px;
  }
  .container-2 {
    flex-direction: column;
  }
  .container-2 .contactInfo {
    margin-bottom: 40px;
  }
  .container-2 .contactInfo,
  .contactForm {
    width: 100%;
  }
  .container-2 .contactInfo .box .text h3,
  .contactForm h2 {
    font-size: 22px;
  }
}
