footer {
  background: #00c894;
  height: auto;
  width: 100vw;
  padding: 40px;
  color: #000;
}
.footer-content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  padding-bottom: 20px;
}
.footer-content h3 {
  font-size: 1.5rem;
  font-weight: 400;
  text-transform: capitalize;
  line-height: 3rem;
}
.footer-content p {
  max-width: 500px;
  margin: 10px auto;
  line-height: 28px;
  font-size: 14px;
}
.socials {
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1rem 0 3rem 0;
}
.socials li {
  margin: 0 10px;
}
.socials a {
  text-decoration: none;
  color: #000;
}
.socials a i {
  font-size: 1.1rem;
  transition: all 0.5s ease;
}
.socials a:hover i {
  color: var(--primary-color);
  transform: scale(1.1);
}
.footer-bottom {
  background: #94ebcd;
  width: 100%;
  padding: 20px 0;
  text-align: center;
}
.footer-bottom p {
  font-size: 14px;
  word-spacing: 2px;
  text-transform: capitalize;
  color: #000;
}
.footer-bottom p a {
  transition: all 0.5s ease-in-out;
}
.footer-bottom p a:hover {
  text-decoration: none;
  transform: scale(1.1);
}
