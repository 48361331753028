@import url(https://fonts.googleapis.com/css2?family=Mulish:wght@300;400;500;600;700;900&display=swap);
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
:root {
  --primary-color: #f44336;
  --secondary-color: #e3f6f5;
}
html {
  scroll-behavior: smooth;
}

footer {
  background: #00c894;
  height: auto;
  width: 100vw;
  padding: 40px;
  color: #000;
}
.footer-content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  padding-bottom: 20px;
}
.footer-content h3 {
  font-size: 1.5rem;
  font-weight: 400;
  text-transform: capitalize;
  line-height: 3rem;
}
.footer-content p {
  max-width: 500px;
  margin: 10px auto;
  line-height: 28px;
  font-size: 14px;
}
.socials {
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1rem 0 3rem 0;
}
.socials li {
  margin: 0 10px;
}
.socials a {
  text-decoration: none;
  color: #000;
}
.socials a i {
  font-size: 1.1rem;
  transition: all 0.5s ease;
}
.socials a:hover i {
  color: var(--primary-color);
  transform: scale(1.1);
}
.footer-bottom {
  background: #94ebcd;
  width: 100%;
  padding: 20px 0;
  text-align: center;
}
.footer-bottom p {
  font-size: 14px;
  word-spacing: 2px;
  text-transform: capitalize;
  color: #000;
}
.footer-bottom p a {
  transition: all 0.5s ease-in-out;
}
.footer-bottom p a:hover {
  text-decoration: none;
  transform: scale(1.1);
}

/* classes */
.container-hero {
  width: 100%;
  max-width: 1140px;
  margin: 0 auto;
}

.d-flex-1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
/* hero section */
.hero {
  position: relative;
  height: 100%;
  min-height: 95vh;
  padding-top: 80px;
}
.hero-wrapper {
  height: 100%;
  min-height: 90vh;
  flex-wrap: wrap;
}
.hero .col-left {
  width: 50%;
}
.hero .hero-image {
  width: 50%;
  text-align: right;
}

.hero .hero-image img {
  width: 500px;
}
.col-left h1 {
  font-size: 40px;
  font-weight: 800;
  line-height: 50px;
  color: #222;
  margin-bottom: 20px;
}
.col-left p {
  font-weight: normal;
  font-size: 15px;
  color: #636363;
  margin-bottom: 50px;
}
.col-left ul {
  padding: 0px;
  margin: 0px;
}
.col-left ul li {
  list-style: none;
  font-weight: normal;
  font-size: 15px;
  line-height: normal;
  color: #636363;
  margin-bottom: 20px;
}
.col-left ul li img {
  vertical-align: bottom;
  margin-right: 10px;
}
.btn-section {
  justify-content: flex-start;
  margin-top: 40px;
}

/* .btn-section button {
  display: inline-block;
  padding: 10px 20px;
  font-weight: 600;
  font-size: 16px;
  line-height: normal;
  color: #fff;
  background-color: #00c894;
  text-transform: capitalize;
  box-shadow: 0px 4px 15px rgba(0, 200, 148, 0.15);
  border-radius: 3px;
}

.btn-section button:hover {
  cursor: pointer;
  text-decoration: none;
} */
.curve-image {
  position: absolute;
  top: auto;
  bottom: -18px;
  left: 0;
}

.shape {
  position: absolute;
}

.shape1 {
  top: 15%;
}
.shape2 {
  top: 20%;
  left: 50%;
}
.shape3 {
  top: 45%;
  left: 40%;
}
.shape4 {
  top: 78%;
  left: 25%;
}
.shape5 {
  top: 70%;
  left: 55%;
}
/* Media Queries */
@media (max-width: 767px) {
  .container-hero {
    padding: 0 20px;
  }
  .hero-wrapper {
    flex-direction: column-reverse;
    min-height: auto;
  }
  .hero .col-left {
    width: 100%;
    padding: 30px 0 150px;
  }
  .col-left h1 {
    font-size: 25px;
    margin-bottom: 15px;
  }
  .hero .hero-image {
    width: 100%;
    text-align: center;
  }
  .hero .hero-image img {
    width: 70%;
  }
  .shape {
    display: none;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .container-hero {
    padding: 0 35px;
  }
  .shape {
    display: none;
  }
  .hero .hero-image img {
    width: 83%;
  }
}
@media (min-width: 1024px) and (max-width: 1230px) {
  .container-hero {
    padding: 0 35px;
  }
  .hero {
    min-height: 94vh;
  }
  .shape {
    display: none;
  }
}

.features {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: linear-gradient(
    var(--secondary-color),
    var(--secondary-color) 50%,
    #fff 50%,
    #fff 100%
  );
}
.features-container {
  width: 1100px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-gap: 20px;
}
.features-container .box {
  position: relative;
  width: 350px;
  background: #fff;
  padding: 100px 40px 60px;
  box-shadow: 0 15px 45px rgba(0, 0, 0, 0.1);
}
.features-container .box:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #00c894;
  transform: scaleY(0);
  transform-origin: top;
  transition: transform 0.5s;
}
.features-container .box:hover:before {
  transform: scaleY(1);
  transform-origin: bottom;
  transition: transform 0.5s;
}
.features-container .box h2 {
  position: absolute;
  left: 40px;
  top: 60px;
  font-size: 4em;
  font-weight: 800;
  z-index: 1;
  opacity: 0.1;
  transition: 0.5s;
}
.features-container .box:hover h2 {
  opacity: 1;
  color: #fff;
  transform: translateY(-40px);
}
.features-container .box h3 {
  position: relative;
  font-size: 1.5em;
  z-index: 2;
  color: #333;
  transition: 0.5s;
}
.features-container .box p {
  position: relative;
  z-index: 2;
  color: #555;
  transition: 0.5s;
  text-align: justify;
}
.features-container .box:hover h3,
.features-container .box:hover p {
  color: #fff;
}

@media screen and (max-width: 760px) {
  .features-container,
  .features-container .box {
    width: 100%;
  }
}

.page-banner {
  background: url(/static/media/banner-3.e3012332.png);
  background-repeat: no-repeat;
  background-size: cover;
  padding: 130px;
  text-align: center;
}

.page-banner h1 {
  font-size: 2.8em;
  font-weight: 500;
  color: #000;
}

.page-banner h3 {
  color: #000;
  margin-bottom: 35px;
  font-weight: 300;
}

.page-banner button {
  color: #000;
  font-weight: 400;
  max-width: 400px;
  padding: 0.5em 2em;
  text-align: center;
  border-radius: 5px;
  background: #00c894;
  transition: all 0.5s ease-out;
}
.page-banner button:hover {
  text-decoration: none;
  transform: scale(1.1);
}
@media screen and (max-width: 767px) {
  .page-banner {
    padding: 15px 0px;
  }
  .page-banner h1 {
    font-size: 25px;
  }
  .page-banner h3 {
    font-size: 20px;
    margin-bottom: 15px;
  }
  .page-banner button {
    padding: 0.3em 1em;
  }
}

.about-area {
  padding: 50px 0 20px;
  background-color: #e3f6f5;
}
.about-img {
  position: relative;
  width: 100%;
}
.about-img img {
  padding-top: 60px;
  width: 100%;
}
.about-text {
  padding-top: 0;
  padding-left: 10px;
}
.about-text h2 {
  color: #000;
  font-size: 30px;
}
.about-text p {
  font-size: 16px;
  color: #000;
  text-align: justify;
}

@media screen and (max-width: 768px) {
  .about-area {
    padding: 20px;
  }
  .about-img {
    width: 90%;
  }
  .about-text {
    padding-left: 0px;
  }
}

/* for desktop */
.whatsapp_float {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 20px;
  right: 30px;
  background-color: #25d366;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  box-shadow: 2px 2px 3px #999;
  z-index: 100;
}

.whatsapp-icon {
  margin-top: 16px;
}
/* for mobile */
@media screen and (max-width: 767px) {
  .whatsapp-icon {
    margin-top: 10px;
  }
  .whatsapp_float {
    width: 40px;
    height: 40px;
    bottom: 20px;
    right: 10px;
    font-size: 22px;
  }
}

.car-section {
  height: 100vh;
  width: 100%;
  background-image: url(/static/media/sky.72db9325.jpg);
  background-size: cover;
  background-position: center;
  position: relative;
  overflow: hidden;
}
.highway {
  height: 200px;
  width: 500%;
  display: block;
  background-image: url(/static/media/road.f04b9b9c.jpg);
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  background-repeat: repeat-x;
  animation: highway 5s linear infinite;
}
@keyframes highway {
  100% {
    transform: translateX(-1400px);
  }
}
.city {
  height: 250px;
  width: 500%;
  background-image: url(/static/media/city.99166d1c.png);
  position: absolute;
  bottom: 200px;
  left: 0;
  right: 0;
  display: block;
  z-index: 1;
  background-repeat: no-repeat;
  animation: city 20s linear infinite;
}
@keyframes city {
  100% {
    transform: translateX(-1400px);
  }
}

.car {
  width: 400px;
  left: 50%;
  bottom: 100px;
  transform: translateX(-50%);
  position: absolute;
  z-index: 2;
}
.car img {
  width: 100%;
  animation: car 1s linear infinite;
}
@keyframes car {
  100% {
    transform: translateY(-1px);
  }

  50% {
    transform: translateY(1px);
  }
  0% {
    transform: translateY(-1px);
  }
}
.wheel {
  left: 50%;
  bottom: 178px;
  transform: translateX(-50%);
  position: absolute;
  z-index: 2;
}
.wheel img {
  width: 72px;
  height: 72px;
  animation: wheel 1s linear infinite;
}
.back-wheel {
  left: -165px;
  position: absolute;
}
.front-wheel {
  left: 80px;
  position: absolute;
}
@keyframes wheel {
  100% {
    transform: rotate(360deg);
  }
}

.about-area-1 {
  padding: 50px 0 20px;
  background-color: #e3f6f5;
  border-top: 10px solid #00c894;
}
.about-img {
  position: relative;
  width: 100%;
}
.about-img img {
  padding-top: 60px;
  width: 100%;
}
.about-text {
  padding-top: 0;
  padding-left: 10px;
}
.about-text h2 {
  color: #000;
  font-size: 30px;
}
.about-text p {
  font-size: 16px;
  color: #000;
  text-align: justify;
}
table {
  border-collapse: collapse;
  width: auto;
}

td,
th {
  border: 1px solid #00c894;
  text-align: center;
  padding: 8px;
}

@media screen and (max-width: 768px) {
  .about-area-1 {
    padding: 20px;
  }
  .about-img {
    width: 90%;
  }
  .about-text {
    padding-left: 0px;
  }
  .about-text h2 {
    font-size: 25px !important;
  }
}

.about-area {
  padding: 50px 0 20px;
  background-color: #e3f6f5;
}
.about-img {
  position: relative;
  width: 100%;
}
.about-img img {
  padding-top: 60px;
  width: 100%;
}
.about-text {
  padding-top: 0;
  padding-left: 10px;
}
.about-text h2 {
  color: #000;
  font-size: 30px;
}
.about-text p {
  font-size: 16px;
  color: #000;
  text-align: justify;
}

@media screen and (max-width: 768px) {
  .about-area {
    padding: 20px;
  }
  .about-img {
    width: 90%;
  }
  .about-text {
    padding-left: 0px;
  }
}

.about-area {
  padding: 50px 0 20px;
  background-color: #e3f6f5;
}
.about-img {
  position: relative;
  width: 100%;
}
.about-img img {
  padding-top: 60px;
  width: 100%;
}
.about-text {
  padding-top: 0;
  padding-left: 10px;
}
.about-text h2 {
  color: #000;
  font-size: 30px;
}
.about-text p {
  font-size: 16px;
  color: #000;
  text-align: justify;
}

@media screen and (max-width: 768px) {
  .about-area {
    padding: 20px;
  }
  .about-img {
    width: 90%;
  }
  .about-text {
    padding-left: 0px;
  }
}

.contact {
  position: relative;
  min-height: 100vh;
  padding: 50px 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #e3f6f5;
  border-top: 10px solid #00c894;
}

.contact .content {
  max-width: 800px;
  text-align: center;
}
.contact .content p {
  font-weight: 500;
  color: #000;
}
.container-2 {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}

.container-2 .contactInfo {
  width: 50%;
  display: flex;
  flex-direction: column;
}

.container-2 .contactInfo .box {
  position: relative;
  padding: 20px 0;
  display: flex;
}

.container-2 .contactInfo .box .icon {
  min-width: 60px;
  height: 60px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-size: 22px;
  color: #00c894;
}

.container-2 .contactInfo .box .text {
  display: flex;
  margin-left: 20px;
  font-size: 16px;
  color: #000;
  flex-direction: column;
  font-weight: 300;
}
.container-2 .contactInfo .box .text h3 {
  font-weight: 500;
  color: #000;
}

.contactForm {
  width: 40%;
  padding: 40px;
  background: #fff;
}
.contactForm h2 {
  font-size: 30px;
  color: #333;
  font-weight: 500;
}
.contactForm .inputBox {
  position: relative;
  width: 100%;
  margin-top: 10px;
}

.contactForm .inputBox input,
.contactForm .inputBox textarea {
  width: 100%;
  padding: 5px 0;
  font-size: 16px;
  margin: 10px 0;
  border: none;
  border-bottom: 2px solid #333;
  outline: none;
  resize: none;
}
.contactForm .inputBox span {
  position: absolute;
  left: 0;
  padding: 5px 0;
  font-size: 16px;
  margin: 10px 0;
  pointer-events: none;
  transition: 0.5s;
  color: #666;
}

.contactForm .inputBox input:focus ~ span,
.contactForm .inputBox input:valid ~ span,
.contactForm .inputBox textarea:focus ~ span,
.contactForm .inputBox textarea:valid ~ span {
  color: #e91e63;
  font-size: 12px;
  transform: translateY(-20px);
}
.contactForm .inputBox input[type='submit'] {
  width: 100px;
  background: #00c894;
  color: #fff;
  border: none;
  cursor: pointer;
  padding: 10px;
  font-size: 18px;
}
.contactForm .inputBox input[type='submit']:hover {
  transform: scale(1.07);
  transition: all 0.5s ease-out;
}

@media screen and (max-width: 991px) {
  .contact {
    padding: 50px;
  }
  .container-2 {
    flex-direction: column;
  }
  .container-2 .contactInfo {
    margin-bottom: 40px;
  }
  .container-2 .contactInfo,
  .contactForm {
    width: 100%;
  }
  .container-2 .contactInfo .box .text h3,
  .contactForm h2 {
    font-size: 22px;
  }
}

