.about-area {
  padding: 50px 0 20px;
  background-color: #e3f6f5;
}
.about-img {
  position: relative;
  width: 100%;
}
.about-img img {
  padding-top: 60px;
  width: 100%;
}
.about-text {
  padding-top: 0;
  padding-left: 10px;
}
.about-text h2 {
  color: #000;
  font-size: 30px;
}
.about-text p {
  font-size: 16px;
  color: #000;
  text-align: justify;
}

@media screen and (max-width: 768px) {
  .about-area {
    padding: 20px;
  }
  .about-img {
    width: 90%;
  }
  .about-text {
    padding-left: 0px;
  }
}
