* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
:root {
  --primary-color: #f44336;
  --secondary-color: #e3f6f5;
}
html {
  scroll-behavior: smooth;
}
