.features {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: linear-gradient(
    var(--secondary-color),
    var(--secondary-color) 50%,
    #fff 50%,
    #fff 100%
  );
}
.features-container {
  width: 1100px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-gap: 20px;
}
.features-container .box {
  position: relative;
  width: 350px;
  background: #fff;
  padding: 100px 40px 60px;
  box-shadow: 0 15px 45px rgba(0, 0, 0, 0.1);
}
.features-container .box:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #00c894;
  transform: scaleY(0);
  transform-origin: top;
  transition: transform 0.5s;
}
.features-container .box:hover:before {
  transform: scaleY(1);
  transform-origin: bottom;
  transition: transform 0.5s;
}
.features-container .box h2 {
  position: absolute;
  left: 40px;
  top: 60px;
  font-size: 4em;
  font-weight: 800;
  z-index: 1;
  opacity: 0.1;
  transition: 0.5s;
}
.features-container .box:hover h2 {
  opacity: 1;
  color: #fff;
  transform: translateY(-40px);
}
.features-container .box h3 {
  position: relative;
  font-size: 1.5em;
  z-index: 2;
  color: #333;
  transition: 0.5s;
}
.features-container .box p {
  position: relative;
  z-index: 2;
  color: #555;
  transition: 0.5s;
  text-align: justify;
}
.features-container .box:hover h3,
.features-container .box:hover p {
  color: #fff;
}

@media screen and (max-width: 760px) {
  .features-container,
  .features-container .box {
    width: 100%;
  }
}
