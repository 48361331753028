.page-banner {
  background: url('../../Images/banner-3.png');
  background-repeat: no-repeat;
  background-size: cover;
  padding: 130px;
  text-align: center;
}

.page-banner h1 {
  font-size: 2.8em;
  font-weight: 500;
  color: #000;
}

.page-banner h3 {
  color: #000;
  margin-bottom: 35px;
  font-weight: 300;
}

.page-banner button {
  color: #000;
  font-weight: 400;
  max-width: 400px;
  padding: 0.5em 2em;
  text-align: center;
  border-radius: 5px;
  background: #00c894;
  transition: all 0.5s ease-out;
}
.page-banner button:hover {
  text-decoration: none;
  transform: scale(1.1);
}
@media screen and (max-width: 767px) {
  .page-banner {
    padding: 15px 0px;
  }
  .page-banner h1 {
    font-size: 25px;
  }
  .page-banner h3 {
    font-size: 20px;
    margin-bottom: 15px;
  }
  .page-banner button {
    padding: 0.3em 1em;
  }
}
